import React from 'react'
import { Col } from 'react-bootstrap'

const SideBar = ({ children, title, contact, phone, email }) => (
    <Col className='sidebarBox' xs='12' sm='12' md='4' lg='4'>
        {children}
        <h3>Call or Contact Today!</h3>
        <p><strong>{title}</strong><br />
        {contact}<br />
        Tel: {phone}<br />
        Email: <a href={`mailto:${email}`}>{email}</a></p>
        <button className='btn btn-primary center-block'><a href='/contact/'>Contact Us</a></button>
    </Col>
)

export default SideBar
