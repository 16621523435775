import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import Banner from '../components/banner'
import Sidebar from '../components/sidebar'
import Seo from '../components/seo'

import AboutSeoImage from '../assets/images/studio-02.jpg'

class About extends React.Component {
    render() {
        return (
            <StaticQuery
            query={graphql`
                query AboutMeta {
                    site {
                        siteMetadata {
                            title
                            description
                            contact
                            emailUrl
                            phone
                            google {
                                googleMapAPIKey
                                googleReCaptchaKey
                            }
                        }
                    }
                }
            `}
            render={data => (
                <>
                <Layout page='about'>
                    <Seo title={`Who We Are | ${data.site.siteMetadata.title}`} description={data.site.siteMetadata.description} image={AboutSeoImage} keywords={[``]} />
                    <Banner title='Who We Are' subTitle='' titleColor='#FFFFFF' subTitleColor='#FFFFFF' />
                    <section>
                        <Container>
                            <Row>
                                <Col xs='12' sm='8' md='8' lg='8'>
                                    <h4>After finishing his apprenticeship in 2003 Mickey has spent the last 18 years honing his skills and working along side some of the best artists in the craft. He opened Black Kat Tattoo in order to provide a clean and comfortable place for both artist and client. Black Kat Tattoo strives to provide a professional and personal experience in a comfortable space.</h4>
                                </Col>
                                <Sidebar title={data.site.siteMetadata.title} contact={data.site.siteMetadata.contact} phone={data.site.siteMetadata.phone} email={data.site.siteMetadata.emailUrl} />
                            </Row>
                        </Container>
                    </section>
                </Layout>
                </>
            )}
            />
        )
    }
}

export default About